import React, { Fragment } from "react";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import About from "../../component/Section/About";
import Contact from "../../component/Section/Contact";
import Service from "../../component/Section/Service";
import settings from "../../settings";

const Index = () => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative">
          <img src={"./../images/homepage/background.png?v=" + settings.general.version} className="images__wrap-head" alt="" />
          <div className="wrapper__bg-head"></div>
          <Navbar />
          <section className="position-relative z-2">
            <div className="container">
              <div className="d-flex flex-column align-items-center">
                <h1 className="semi-bold font__size--90 text__90-1024 text__90-md text__90-sm text__90-xs  color__white text-uppercase mb-4 text-center">{settings.general.title}</h1>
                <p className="medium roboto font__size--16 text__16-1024 color__white w-75 mb-4 text-center" style={{whiteSpace: "pre-wrap"}}>{settings.general.description}</p>

                <div className="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-center">
                  <a href="#about" className="btn__mm-w-60 semi-bold rounded-0 font__size--14 text__14-1024 btn btn__orange shadow color__white text-uppercase">О нас</a>
                  <a href="#service" className="btn__mm-w-60  semi-bold font__size--14 text__14-1024 btn btn__outlined--white color__white no__opacity h__black rounded-0 shadow text-uppercase mt-3 mt-sm-0 ml-sm-4">Наши услуги</a>
                </div>
              </div>
            </div>
          </section>
        </div>

        <About />
        <Service />
        <Contact />
        <Footer />
      </div >
    </Fragment >
  );
};

export default Index;
