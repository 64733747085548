import React from 'react'
import settings from "../../settings"

const Footer = (props) => {
    return (
        <section className="pb-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mb-4 mb-md-0">
                        <img src={"./../images/homepage/logo_footer.png?v=" + settings.general.version} alt={settings.general.title} style={{maxHeight: '100px', maxWidth: '300px'}} />
                        <p className="normal font__size--16 text__16-1024 roboto color__gray-1" style={{whiteSpace: "pre-wrap"}}>{settings.footer.description}</p>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4 mb-4 mb-md-0">
                        <div className="h-100 d-flex flex-column justify-content-end">
                            <div className="mb-2">
                                <img src={"./../images/icons/address.png?v=" + settings.general.version} alt="" />
                                <span className="semi-bold font__size--14 text__14-1024 text-uppercase ml-2">{settings.footer.address}</span>
                            </div>
                            <div className="mb-2">
                                <img src={"./../images/icons/phone.png?v=" + settings.general.version} alt="" />
                                <a href={`tel:${settings.footer.phone}`} style={{color: 'black'}}><span className="semi-bold font__size--14 text__14-1024 text-uppercase ml-2">{settings.footer.phone}</span></a>
                            </div>
                            <div className="mb-4">
                                <img src={"./../images/icons/email.png?v=" + settings.general.version} alt="" />
                                <a href={`mailto:${settings.footer.email}`} style={{color: 'black'}}><span className="semi-bold font__size--14 text__14-1024 text-uppercase ml-2">{settings.footer.email}</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center normal roboto font__size--16 text__16-1024 color__gray-1 mt-5">{settings.footer.copyright}</div>
            </div>
        </section>
    )
}

export default Footer