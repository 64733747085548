import React from 'react'
import { NavLink } from 'react-router-dom'

const CardSevice = (props) => {
    return (
        <NavLink
            to={`/pages/${props.data.id}`} className="wrapper__card-service color__black d-inline-block w-100 h-100">
            <div className="desc d-flex flex-column h-100">
                <p className="semi-bold font__size--12 text__12-1024 color__orange text-uppercase">{props.data.section}</p>
                <div className="h5 medium font__size--20 text__20-1024 text-uppercase">{props.data.title}</div>
                <p className="normal font__size--16 text__16-1024 roboto color__gray-2 flex-fill">
                    {props.data.description}
                </p>
                <div className="d-inline-block">
                    <p className='color__orange text-uppercase'>Читать далее</p>
                </div>
            </div>
        </NavLink>
    )
}

export default CardSevice