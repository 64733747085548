import React, { useState } from 'react'
import settings from "../../settings"

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [formSent, setFormSent] = useState(false);

    const sendForm = (e) => {
        let element = e.target;
        while (element.tagName != 'FORM')
            element = element.parentElement;
        if (element.reportValidity()) {
            const data = new URLSearchParams();
            data.append('name', name);
            data.append('email', email);
            data.append('phone', phone);
            data.append('message', message);

            fetch('/api/submit', {
                method: 'post',
                body: data,
            })
            .then((resp) => resp.status)
            .then((status) => setFormSent(true));
        }
    }

    return (
        <section className="position-relative" id="contact">
            <img src={"./../images/homepage/contact.png?v=" + settings.general.version} className="images__wrap-contact" alt="" />
            <div className="wrapper__bg-orange-wrap"></div>
            <div className="container position-relative z-2">
                <div className="row">
                    <div className="col-md-6 mb-4 my-md-auto">
                        <h3 className="bold font__size--58 text__50-1024 text__50-sm text__50-xs color__white text-uppercase mb-4">{settings.contact.title}</h3>
                        <p className="roboto normal font__size--16 text__16-1024 color__white" style={{whiteSpace: "pre-wrap"}}>{settings.contact.description}</p>
                    </div>
                    <div className="col-md-6 col-xl-5 offset-xl-1">
                        <div className="text-white font__size--25 mt-4" style={{display: formSent? 'block' : 'none'}}>Спасибо, мы получили ваше сообщение!</div>
                        <form className="wrapper__form-contact bg__white" style={{display: formSent? 'none' : 'block'}}>
                            <div className="form-group">
                                <label className="semi-bold font__size--14 text__14-1024 text-uppercase">Имя</label>
                                <input name="name" type="text" className="form-control wrapper__field" placeholder="Ваше имя..." onChange={(e) => {setName(e.target.value)}} required />
                            </div>
                            <div className="form-group">
                                <label className="semi-bold font__size--14 text__14-1024 text-uppercase">Электронная почта</label>
                                <input name="email" type="email" className="form-control wrapper__field" placeholder="Ваша электронная почта..." onChange={(e) => {setEmail(e.target.value)}} required />
                            </div>
                            <div className="form-group">
                                <label className="semi-bold font__size--14 text__14-1024 text-uppercase">Номер телефона</label>
                                <input name="phone" type="text" className="form-control wrapper__field" placeholder="Ваш номер телефона..." onChange={(e) => {setPhone(e.target.value)}} required />
                            </div>
                            <div className="form-group mb-4">
                                <label className="semi-bold font__size--14 text__14-1024 text-uppercase">Сообщение</label>
                                <textarea name="message" className="form-control wrapper__field textarea" cols="30" rows="5" placeholder="Ваше сообщение..." onChange={(e) => {setMessage(e.target.value)}} required></textarea>
                            </div>
                            <button className="semi-bold rounded-0 font__size--12 text__14-1024 btn btn__orange shadow color__white text-uppercase" type="button" onClick={sendForm}>Отправить</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact