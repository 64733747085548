import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './container/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
// import $ from "jquery";
import 'popper.js/dist/popper.min.js';
import './assets/css/main.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './assets/css/froala_style.min.css';

const root = createRoot(document.querySelector('#root')); 
root.render(<App tab="home" />);