import React, { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Contact from '../../component/Section/Contact'
import Head from '../../component/Section/Head'
import settings from '../../settings'

const Page = () => {
    const [page, setPage] = useState(null);

    const updatePage = () => {
        let page_id = document.location.pathname.split('/')[2] - 0;
        for (let section of settings.sections) {
            for (let _page of section.pages) {
                if (_page.id === page_id) {
                    _page.section = section.title;
                    setPage(_page);
                }
            }
        }
    }

    const location = useLocation();
    useEffect(() => {
        updatePage();
    }, [location]);
    
    if (!page)
        updatePage();

    return (
        <Fragment>
            <div className="overflow-hidden">
                <div className="position-relative">
                    <img src={"./../images/homepage/background.png?v=" + settings.general.version} className="images__wrap-head" alt="" />
                    <div className="wrapper__bg-head"></div>
                    <Navbar />
                    <Head default="Главная" title={page?.title} current={page?.section} />
                </div>

                <section className='pt-0'>
                    <div className="container">
                        <div className="row">
                            <div className="mt-5 mb-md-0 mx-3" dangerouslySetInnerHTML={{__html: page?.content}} />
                        </div>
                    </div>
                </section>

                <Contact />

                <Footer />
            </div >
        </Fragment>
    )
}

export default Page