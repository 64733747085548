import React, { Fragment } from "react";
import {Helmet} from 'react-helmet'
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";

import Homepage from "./homepage/Index";
import Page from "./homepage/Page";
import ScrollToTop from '../component/Other/ScrollToTop';
import settings from "../settings";

const Index = (props) => {
  return (
    <Fragment>
      <Router forceRefresh>
        <ScrollToTop/>
        <Helmet meta={[
          {
              name: "title",
              content: settings.seo.title
          },
          {
              name: "description",
              content: settings.seo.description
          },
          {
              name: "keywords",
              content: settings.seo.keywords
          }
        ]} />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/pages/:id" element={<Page />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </Fragment>
  );
};

export default Index;
