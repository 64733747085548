import React from 'react'
import settings from "../../settings"

const About = () => {
    return (
        <section id="about">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-4 my-md-auto">
                        <img src={"./../images/homepage/about.png?v=" + settings.general.version} className="w-100" alt="" />
                    </div>
                    <div className="col-md-7 col-lg-6 my-auto pl-lg-5">
                        <p className="semi-bold font__size--14 text__14-1024 color__orange text-uppercase">О нас</p>
                        <h3 className="bold font__size--58 text__50-1024 text__50-sm text__50-xs text-uppercase">{settings.about.title}</h3>
                        <p className="roboto semi-bold font__size--16 text__16-1024">{settings.about.subtitle}</p>
                        <p className="roboto normal font__size--16 text__16-1024 color__gray-1" style={{whiteSpace: "pre-wrap"}}>{settings.about.description}</p>
                        <a href="#contact" className="semi-bold rounded-0 font__size--14 text__14-1024 btn btn__orange shadow color__white text-uppercase">Связаться с нами</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About