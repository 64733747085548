import React from 'react'
import CardSevice from '../Card/CardSevice'
import settings from '../../settings'

const Service = () => {
    let pages = [];
    for (let section of settings.sections)
        for (let page of section.pages)
            if (pages.length < 6) {
                page.section = section.title;
                pages.push(page);
            }

    return (
        <section id="service">
            <div className="container">
                <div className="text-center mb-5">
                    <p className="semi-bold font__size--14 text__14-1024 color__orange text-uppercase">Что мы делаем</p>
                    <h3 className="bold font__size--58 text__50-1024 text__50-sm text__50-xs text-uppercase">Наши услуги</h3>
                </div>

                <div className="row mb-5">
                    {
                        pages.map((page) => {
                            return <div className="col-lg-4 mb-4" key={pages.indexOf(page)} >
                                <CardSevice data={page} />
                            </div>
                        })
                    }
                </div>

            </div>
        </section>
    )
}

export default Service